import React from "react";
import { Link } from "gatsby";
import { currentYear } from "../Utility/TimeUtility";
import styled from "@emotion/styled";

export default () => (
  <Footer>
    <FooterContent>
      <ContactCopyright>
        <Nav>
          <NavUl>
            <NavLi>
              <StyledLink to="/about/">About</StyledLink>
            </NavLi>
            <NavLi>
              <StyledLink to="/contact/">Contact</StyledLink>
            </NavLi>
            {/* <NavLi>
              <StyledLink to="/privacy/">Privacy</StyledLink>
            </NavLi> */}
          </NavUl>
        </Nav>
        <Small>
          <small>{"©  " + currentYear() + " ScData.io"}</small>
          <small>
            All game content and materials are copyright of Cloud Imperium Rights LLC and Cloud Imperium Rights Ltd.. Star Citizen®, Squadron 42®, Roberts Space Industries®, and
            Cloud Imperium® are registered trademarks of Cloud Imperium Rights LLC. All rights reserved.
          </small>
        </Small>
      </ContactCopyright>
    </FooterContent>
  </Footer>
);
const ContactCopyright = styled.div`
  margin-right: 50px;
`;
const Footer = styled.footer`
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  position: relative;
  width: auto;
  color: var(--color);
  border-top: rgba(220, 220, 220, 0.9);
  background-color: var(--background-color);
  padding: 20px 40px;
`;
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const NavUl = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -12px;
  padding: 0;
`;
const NavLi = styled.li`
  align-items: center;
  display: flex;
  margin: 4px;
`;
const StyledLink = styled((props) => <Link {...props} />)`
  border-radius: 4px;
  line-height: 24px;
  padding: 8px;
  text-decoration: none;
  color: var(--color);
  transition: background-color, color 0.3s ease;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    border: 0;
    color: var(--link-hover-color);
  }
`;
const Small = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875em;
  font-weight: 400;
`;
const FooterContent = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
`;
