import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "@emotion/styled";
import { ThemeToggler } from "gatsby-plugin-dark-mode";
import "./darkLightToggle.css";

export default class LightDarkToggle extends React.Component {
  render() {
    return (
      <ThemeToggler>
        {({ theme, toggleTheme }) => (
          <Toggle htmlFor="toggleLightDark">
            <StaticQuery
              query={graphql`
                query {
                  moonImage: file(
                    relativePath: { eq: "LightDarkToggle/moon.png" }
                  ) {
                    childImageSharp {
                      fixed(width: 16, height: 16, quality: 95) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                  sunImage: file(
                    relativePath: { eq: "LightDarkToggle/sun.png" }
                  ) {
                    childImageSharp {
                      fixed(width: 16, height: 16, quality: 95) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                }
              `}
              render={data => (
                <Track>
                  <Moon
                    className={theme === "dark" ? "moonVisible" : "moonHidden"}
                  >
                    <Img
                      fixed={data.moonImage.childImageSharp.fixed}
                      alt="Toggle Dark Theme"
                    />
                  </Moon>
                  <Sun
                    className={theme === "dark" ? "sunHidden" : "sunVisible"}
                  >
                    <Img
                      fixed={data.sunImage.childImageSharp.fixed}
                      alt="Toggle Light Theme"
                    />
                  </Sun>
                  <ToggleButton
                    className={
                      theme === "dark"
                        ? "toggleButton switchRight"
                        : "toggleButton"
                    }
                  />
                  <StyledInput
                    name="toggleLightDark"
                    id="toggleLightDark"
                    aria-label="Switch between Dark and Light mode"
                    type="checkbox"
                    onChange={e =>
                      toggleTheme(e.target.checked ? "dark" : "light")
                    }
                    checked={theme === "dark"}
                  />
                </Track>
              )}
            />
          </Toggle>
        )}
      </ThemeToggler>
    );
  }
}
const Toggle = styled.label`
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 18px 24px 18px 24px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;
const Track = styled.div`
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: var(--dark-toggle-color);
  box-shadow: 0px 0px 10px 0px grey;
  transition: all 0.2s ease;
`;
const Moon = styled.div`
  position: absolute;
  width: 17px;
  height: 17px;
  left: 29px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
`;
const Sun = styled.div`
  position: absolute;
  width: 17px;
  height: 17px;
  left: 53px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
`;
const ToggleButton = styled.div``;
const StyledInput = styled.input`
  appearance: none;
`;
