import moment from "moment";

export function timeSincePosted(timePosted) {
  const startTime = moment(timePosted, ["YYYY-MM-DDTHH:mm:ssZ", "M/D/YYYY"]);
  const days = moment()
    .utc()
    .diff(moment(startTime), "days");
  if (days < 1) {
    const hours = moment()
      .utc()
      .diff(startTime, "hours");
    if (hours < 1) {
      const minutes = moment()
        .utc()
        .diff(startTime, "minutes");
      if (minutes <= 1) {
        return "Posted 1 minute ago";
      }
      return "Posted " + minutes + " minutes ago";
    }
    if (hours === 1) {
      return "Posted 1 hour ago";
    }
    return "Posted " + hours + " hours ago";
  }
  if (days === 1) {
    return "Posted 1 day ago";
  }
  return "Posted " + days + " days ago";
}
export function getCurrentMonthYear() {
  return moment().format("MMMM YYYY");
}
export function isExpired(timeExpired) {
  const endTime = moment(timeExpired, ["YYYY-MM-DDTHH:mm:ssZ", "M/D/YYYY"]);
  const hours = endTime.diff(moment().utc(), "hours");
  if (hours <= -1) {
    return true;
  }
  return false;
}
export function expiredString(timeExpired) {
  if (timeExpired === null) {
    return "Working";
  }
  const endTime = moment(timeExpired, ["YYYY-MM-DDTHH:mm:ssZ", "M/D/YYYY"]);
  const days = endTime.diff(moment().utc(), "days");
  if (days < 0) {
    return "Expired";
  }
  return "Limited";
}
export function timeToExpiration(timeExpired) {
  if (timeExpired === null) {
    return "";
  }
  const endTime = moment(timeExpired, ["YYYY-MM-DDTHH:mm:ssZ", "M/D/YYYY"]);
  const days = endTime.diff(moment().utc(), "days");
  if (days < 0) {
    return "";
  }
  if (days < 1) {
    const hours = endTime.diff(moment().utc(), "hours");
    if (hours <= -1) {
      return "Expired";
    }
    if (hours < 1) {
      return "Expires in less than 1 hour";
    }
    return "Expires in " + hours + " hours";
  }
  if (days === 1) {
    return "Expires in 1 day";
  }
  return "Expires in " + days + " days";
}
export function timeUntilExpired(timeExpired) {
  if (timeExpired === null) {
    return "Working";
  }
  const endTime = moment(timeExpired, ["YYYY-MM-DDTHH:mm:ssZ", "M/D/YYYY"]);
  const days = endTime.diff(moment().utc(), "days");
  if (days < 0) {
    return "Expired";
  }
  if (days < 1) {
    const hours = endTime.diff(moment().utc(), "hours");
    if (hours <= -1) {
      return "Expired";
    }
    if (hours < 1) {
      return "Expires in less than 1 hour";
    }
    return "Expires in " + hours + " hours";
  }
  if (days === 1) {
    return "Expires in 1 day";
  }
  return "Expires in " + days + " days";
}

export function currentYear() {
  return moment().year();
}
