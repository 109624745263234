import React from "react";
import { Link } from "gatsby";
// import Logo from "./logo";
import LightDarkToggle from "../LightDarkToggle/LightDarkToggle";
import "./luxbar.css";

const Navigation = () => (
  <header className="header header-default">
    <input type="checkbox" id="header-checkbox" className="header-checkbox" />
    <div className="header-menu header-menu-right header-menu-dark">
      <ul className="header-navigation">
        <li className="header-header">
          <Link className="header-brand" to="/">
            ScData.io
          </Link>
          <label className="header-hamburger header-hamburger-doublespin" htmlFor="header-checkbox">
            <span />
          </label>
        </li>
        <li>
          <LightDarkToggle />
        </li>
        <li className="header-item">
          <Link to="/star-citizen-referral-code/">Referral Code</Link>
        </li>
        <li className="header-item dropdown">
          <Link to={`#`} title="Charts">
            Charts
          </Link>
          <ul>
            <li>
              <Link to="/ship-cost-uec-dollar-ratio/">Ship Cost UEC/Dollar Ratio</Link>
            </li>
            <li>
              <Link to="/ship-cargo-capacity/">Ship Cargo Capacity</Link>
            </li>
            <li>
              <Link to="/star-citizen-loaner-values/">Ship Loaner Value</Link>
            </li>
          </ul>
        </li>
        <li className="header-item">
          <a href="https://robertsspaceindustries.com/enlist?referral=STAR-JN3Y-CT7Y">Join Star Citizen</a>
        </li>
      </ul>
    </div>
  </header>
);

export default Navigation;
