import React from "react";
import Navigation from "./Navigation/header";
import Footer from "./Navigation/footer";
// import BackgroundImage from "./backgroundImage";
import "./base.css";

export default ({ children }) => (
  <div className="root">
    <Navigation />
    <div className="main">
      {/* <BackgroundImage /> */}
      <div className="container">
        <div className="leaderBoard" id="layout-banner"></div>
        {children}
        <div className="adCardFooter" id="layout-footer" />
      </div>
    </div>
    <Footer />
  </div>
);
